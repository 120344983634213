import { useEffect, useState } from "react";

export const  useIsOnline =()=> {
    const [isOnline, setIsOnline] = useState(false);
    
 
    const setOff = () => {
      console.log("setOff");
  
      setIsOnline(false);
    };
    const setOn = () => {
      console.log("setOn");
  
      setIsOnline(true);
    };
    // effects
    useEffect(() => {
      window.addEventListener("offline", setOff);
      window.addEventListener("online", setOn);
      window.addEventListener("load", () => {
        setIsOnline(navigator.onLine)
        console.log(navigator.onLine,"navigator.onLine")
        // console.log(navigator.online,"navigator.online")
      });
      window.addEventListener("onload", () => {
        console.log("onload::::::");
        setIsOnline(navigator.onLine);
        console.log(navigator.onLine, "navigator.onLine");
        // console.log(navigator.online,"navigator.online")
      });
      return () => {
        window.removeEventListener("offline", setOff);
        window.removeEventListener("online", setOn);
      };
    }, []);
  
    return isOnline;
  }