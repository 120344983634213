import * as React from 'react';

import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { useIsOnline } from '../hooks';

export default function OnlineStatusBar() {
  const isOnline = useIsOnline()
  
  if (isOnline) {
    return null
  }

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>

        <Toolbar>
          <Typography variant="subtitle1" component="div" sx={{ flexGrow: 1 }}>
            You are offline, please connect to the internet to have access to the latest content and all the features like purchases etc.
          </Typography>

        </Toolbar>

      </Box>


    </>
  );
}
