import {
  collection,
  getDocs,
  query,
  doc,
  getDoc,
  // arrayUnion,
  updateDoc,
  setDoc,
} from "firebase/firestore";

import { remoteDB } from "..";

export interface RemoteLessonItemDoc {
  title: string;
  level: number;
  recId: string;
  notes: string;
  ver: number;
  id: string;
}

export const NO_DOC = "No such document!"

export const getLessons: () => Promise<RemoteLessonItemDoc[]> = async () => {
  const ref = collection(remoteDB, "lessonRecordings");
  const q = query(ref);
  console.log(ref, "refffffff");
  const querySnapshot = await getDocs(q);

  const out: any = [];
  querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    // console.log(doc.id, " => ", doc.data());
    out.push(doc);
  });

  return out.map((doc: any) => {
    const { title, recId, level, notes, ver } = doc.data();
    return { id: doc.id, title, recId, level, notes, ver };
  });
};

export const checkLessonUnlocked: (
  lessonIdToCheck: string,
  uid: string
) => Promise<boolean> = async (lessonIdToCheck, uid) => {
  console.log("recId::::checkLessonUnlocked::", lessonIdToCheck);
  const docRef = doc(remoteDB, "userInfo", uid);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    console.log("Document data:", docSnap.data());

    const { unlockedLessons = [] } = docSnap.data();

    const hasAccess = unlockedLessons?.some((unlockedId: string) => {
      return unlockedId === lessonIdToCheck;
    });

    console.log("hasAccess::::::", hasAccess);
    return hasAccess;
  } else {
    // doc.data() will be undefined in this case
    console.log("No such document!");
    return false;
  }
};
export const getLessonsUnlocked: (
  userId: string
) => Promise<string[]> = async (userId) => {
  console.log("recId::::getLessonsUnlocked::", userId);
  const docRef = doc(remoteDB, "userInfo", userId);
  console.log('remoteDB.ts  >>> docRef::::', docRef);

  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    console.log("Document data:", docSnap.data());

    const { unlockedLessons = [] } = docSnap.data();

    console.log("unlockedLessons::::::", unlockedLessons);
    return unlockedLessons;
    // return ["l1l5", "l2l3"];
  } else {
    // doc.data() will be undefined in this case
    console.error(NO_DOC);
    return [NO_DOC];
  }
};
export const updateLessonsUnlocked: (
  uid: string,
  updatedUnlocked: string[]
) => Promise<string[]> = async (uid, updatedUnlocked) => {
  console.log("userEmail::::::", uid);
  console.log("updatedUnlocked::::::", updatedUnlocked);

  const docRef = doc(remoteDB, "userInfo", uid);
  const docSnap = await getDoc(docRef);
  // db.collection('cities').doc('DC');
  if (docSnap.exists()) {
    console.log("Document data:", docSnap.data());
    const resUpdate = await updateDoc(docRef, {
      unlockedLessons: [...updatedUnlocked],
      // unlockedLessons: arrayUnion(...updatedUnlocked),
    });
    // const { unlockedLessons = [] } = docSnap.data();
    console.log("resUpdate::::::", resUpdate);
    // console.log("unlockedLessons::::::", unlockedLessons);
    // return unlockedLessons;
    return [""];
  } else {
    // doc.data() will be undefined in this case
    console.error(NO_DOC);
    return [""];
  }
};
export const createUserInfo: (uid: string) => Promise<string[]> = async (
  uid
) => {
  console.log("createUserInfo uid::::::", uid);
  if (!uid) {
    // doc.data() will be undefined in this case
    console.error("createUserInfo No ID provided!");
    return [""];
  }
  console.log("uid::::::", uid);
  try {
    const docRef = doc(remoteDB, "userInfo", uid);
    const docSnap = await getDoc(docRef);
    // db.collection('cities').doc('DC');

    console.log("Document data:", docSnap.data());

    const respCreate = await setDoc(
      docRef,
      { unlockedLessons: ["empty"] },
      { merge: true }
    );

    // const { unlockedLessons = [] } = docSnap.data();
    console.log("respCreate::::::", respCreate);
    // console.log("unlockedLessons::::::", unlockedLessons);
    // return unlockedLessons;
    return ["empty"];
  } catch (error) {
    console.log("createUserInfo error::::::", error);
    return [""];
  }
};
