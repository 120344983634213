import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
} from "firebase/auth";

import { FacebookAuthProvider } from "firebase/auth";

export const registerUser = (email: any, password: any) =>
  createUserWithEmailAndPassword(getSingeltonAuth().auth, email, password)
    .then((userCredential) => {
      // Signed in
      const user = userCredential.user;
      console.log(user, "Registred");

      // ...
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.error(errorCode, errorMessage, "createUserWithEmailAndPassword");

      // ..
    });

export const logInWithEmailAndPassword = (email: any, password: any) =>
  signInWithEmailAndPassword(getSingeltonAuth().auth, email, password)
    .then((userCredential) => {
      // Signed in
      const user = userCredential.user;
      console.log(user, "logInWithEmailAndPassword");
      // ...
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.error(errorCode, errorMessage, "logInWithEmailAndPassword");
    });

export const signInWithPopupFacebook = () =>
  signInWithPopup(getSingeltonAuth().auth, getSingeltonAuth().fbProvider)
    .then((result) => {
      // The signed-in user info.
      const user = result.user;

      // This gives you a Facebook Access Token. You can use it to access the Facebook API.
      const credential = FacebookAuthProvider.credentialFromResult(result);
      console.log(credential, "FB credential");

      const accessToken = credential?.accessToken;
      console.log(user, accessToken, "user, accessToken");

      // ...
    })
    .catch((error) => {
      // Handle Errors here.
      const errorCode = error.code;
      const errorMessage = error.message;
      // The email of the user's account used.
      const email = error.email;
      // The AuthCredential type that was used.
      const credential = FacebookAuthProvider.credentialFromError(error);
      console.log(errorCode, errorMessage, email, credential, "FB ERROR");

      // ...
    });
export const signInWithPopupGoogle = () =>
  signInWithPopup(getSingeltonAuth().auth, getSingeltonAuth().googleProvider)
    .then((result) => {
      // The signed-in user info.
      // This gives you a Google Access Token. You can use it to access the Google API.
      const credential = GoogleAuthProvider.credentialFromResult(result);
      // The signed-in user info.
      const user = result.user;
      console.log(credential, "FB credential");

      const accessToken = credential?.accessToken;
      console.log(user, accessToken, "user, accessToken");

      // ...
    })
    .catch((error) => {
      // Handle Errors here.
      const errorCode = error.code;
      const errorMessage = error.message;
      // The email of the user's account used.
      const email = error.email;
      // The AuthCredential type that was used.
      const credential = GoogleAuthProvider.credentialFromError(error);
      console.log(errorCode, errorMessage, email, credential, "GOOGLE ERROR");

      // ...
    });

const getSingeltonAuth = () => {
  const fbProvider = new FacebookAuthProvider();
  const googleProvider = new GoogleAuthProvider();

  const auth = getAuth();

  return { auth, fbProvider, googleProvider };
};


