import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

import reportWebVitals from './reportWebVitals';
import {
  BrowserRouter, Routes,
  Route,
} from "react-router-dom";
// import Abel from '../assets/Abel-Regular.ttf';
import { createTheme, CssBaseline, ThemeProvider } from '@mui/material';
import { getFirestore } from "firebase/firestore";

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

// import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// Initialize Firebase
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import common_mg from "./translations/mg/common.json";
import common_en from "./translations/en/common.json";
import PrivacyPolicy from './components/PrivacyPolicy';
import RemoveAccount from './components/RemoveAccount';
import Profile from './components/Profile';
import Admin from './components/pages/Admin';
i18next.init({
  interpolation: { escapeValue: false },  // React already does escaping
  lng: 'mg',                              // language to use
  resources: {
    en: {
      common: common_en               // 'common' is our custom namespace
    },
    mg: {
      common: common_mg
    },
  }, // React already does escaping
});

const firebaseConfig = {
  apiKey: "AIzaSyBB7ZaCJjrc65uH4OYKq18qtV-MDLTshKU",
  authDomain: "malen-firebase.firebaseapp.com",
  projectId: "malen-firebase",
  storageBucket: "malen-firebase.appspot.com",
  messagingSenderId: "676323312582",
  appId: "1:676323312582:web:96c530161f1922f0cc28c7",
  measurementId: "G-SB6MBL5LSH"
};

const firebaseApp = initializeApp(firebaseConfig);
export const remoteDB = getFirestore(firebaseApp);

const messaging = getMessaging(firebaseApp);
getToken(messaging, { vapidKey: "BORZzDd9N7BBLJ1UHRc8KuvHQNxFfActujJxADuISHi98fFij7h8LOFf2gwFAEgYBbmo_PTysE9RS4Uz6h-3sh8" }).then((currentToken) => {
  if (currentToken) {
    // Send the token to your server and update the UI if necessary
    console.log('getTokencurrentToken::::::', currentToken);

    // ...
  } else {
    // Show permission request UI
    console.log('getTokenNo registration token available. Request permission to generate one.');
    // ...
  }
}).catch((err) => {
  console.log('getTokenAn error occurred while retrieving token. ', err);
  // ...
});;
export const onMessageListener = () =>
  new Promise((resolve) => {
    console.log("onMessage", onMessage);
    onMessage(messaging, (payload) => {
      console.log("payload", payload);

      resolve(payload);
    });
  });

const theme = createTheme({
  typography: {
    fontFamily: 'Roboto',
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Abel';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('Abel'), local('Abel-Regular'), url('./assets/Abel-Regular.ttf') format('ttf');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
      `,
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <I18nextProvider i18n={i18next}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<App />} />
            <Route path="privacy-policy" element={<PrivacyPolicy />} />
            <Route path="remove-account" element={<RemoveAccount />} />
            <Route path="profile" element={<Profile />} />
            <Route path="admin" element={<Admin />} />
          </Routes>
        </BrowserRouter>
      </I18nextProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();


//FIREBASE

