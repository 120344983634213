import axios from "axios";
import { MALEN_BE } from "../api/malen-be";

export const request = (url: string) =>
  axios.create({
    baseURL: url,
    timeout: 10000,
  });

export const downloadFileRequest = (
  userToken: string,
  lessonRecId: string,
  lessonId: string,
  onProgress: (p: number) => void
) => {
  return (
    fetch(`${MALEN_BE}/lessons`, {
      method: "post",
      headers: new Headers({
        Authorization: `Bearer ${userToken}`,
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded'
      }),
      body: JSON.stringify({
        lessonRecId,
        lessonId,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw Error(response.status + " " + response.statusText);
        }
        // ensure ReadableStream is supported
        if (!response.body) {
          throw Error("ReadableStream not yet supported in this browser.");
        }
        // store the size of the entity-body, in bytes
        const contentLength = response.headers.get("content-length");
        // ensure contentLength is available
        if (!contentLength) {
          throw Error("Content-Length response header unavailable");
        }
        // parse the integer into a base-10 number
        const total = parseInt(contentLength, 10);
        let loaded = 0;
        return new Response(
          // create and return a readable stream
          new ReadableStream({
            start(controller) {
              const reader = response?.body?.getReader();
              read();
              function read() {
                reader
                  ?.read()
                  .then(({ done, value }) => {
                    if (done) {
                      controller.close();
                      return;
                    }
                    loaded += value?.byteLength || 0;
                    const stat = progress({ loaded, total });
                    onProgress(stat);
                    console.log(stat);
                    controller.enqueue(value);
                    read();
                  })
                  .catch((error) => {
                    console.error(error);
                    controller.error(error);
                  });
              }
            },
          })
        );
      })
      .then((response) =>
        // construct a blob from the data
        response.blob()
      )
      //   .then(data => {
      //     // insert the downloaded image into the page
      //     // document.getElementById('img').src = URL.createObjectURL(data);
      //   })
      .catch((error) => {
        console.error(error);
      })
  );
};
function progress({
  loaded,
  total,
}: {
  loaded: number;
  total: number;
}): number {
  return Math.round((loaded / total) * 100);
}
