import { getAuth } from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";
import { Link } from "react-router-dom";
export default function Profile() {

    const [user, error] = useAuthState(getAuth());
    return (
        <div style={{ background: '#f3a81d', color: 'white', padding: '30px' }}>
            <Link to="/">BACK TO MAINSCREEN</Link>
            <h1>Profile information and settings</h1>
<p>Email: {user?.email }</p>
<p>Name: {user?.displayName }</p>
<p>User Id: {user?.uid }</p>
              {error}
            <Link to="/remove-account">How to remove account</Link>
        </div>)
}