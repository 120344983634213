// import 'tippy.js/dist/tippy.css'
// import './index.css'
// import ReactDOM from 'react-dom'
// import React, { useEffect, useState } from 'react'
import {
    createPlateUI,
    // HeadingToolbar,
    // MentionCombobox,
    Plate,
    createAlignPlugin,
    createAutoformatPlugin,
    createBlockquotePlugin,
    createBoldPlugin,
    // createCodeBlockPlugin,
    createCodePlugin,
    createExitBreakPlugin,
    createHeadingPlugin,
    createHighlightPlugin,
    createKbdPlugin,
    createImagePlugin,
    createItalicPlugin,
    createLinkPlugin,
    createListPlugin,
    createMediaEmbedPlugin,
    createNodeIdPlugin,
    createParagraphPlugin,
    createResetNodePlugin,
    createSelectOnBackspacePlugin,
    createSoftBreakPlugin,
    createDndPlugin,
    createStrikethroughPlugin,
    createSubscriptPlugin,
    createSuperscriptPlugin,
    createTablePlugin,
    createTodoListPlugin,
    createTrailingBlockPlugin,
    createUnderlinePlugin,
    createComboboxPlugin,
    createMentionPlugin,
    createIndentPlugin,
    createFontColorPlugin,
    createFontBackgroundColorPlugin,
    createDeserializeMdPlugin,
    createDeserializeCsvPlugin,
    createNormalizeTypesPlugin,
    createFontSizePlugin,
    createHorizontalRulePlugin,
    createPlugins,
    createDeserializeDocxPlugin,
    createJuicePlugin,
    // PlateProps,
} from '@udecode/plate'
// import {
//   createExcalidrawPlugin,
//   ELEMENT_EXCALIDRAW,
//   ExcalidrawElement,
// } from '@udecode/plate-ui-excalidraw'
// import { MarkBallonToolbar, ToolbarButtons } from './config/components/Toolbars'
import { withStyledPlaceHolders } from './config/components/withStyledPlaceHolders'
// import { withStyledDraggables } from './config/components/withStyledDraggables'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
// import { MENTIONABLES } from './config/mentionables'
import { CONFIG } from './config/config'
// import { VALUES } from './config/values/values'

// import fs from 'fs';
// import { unified } from 'unified';
// import markdown from 'remark-parse';
// import slate from 'remark-slate';


// Migrate to v8 - Part 1: https://www.loom.com/share/71596199ad5a47c2b58cdebab26f4642
// Migrate to v8 - Part 2: https://www.loom.com/share/d85c89220ffa4fe2b6f934a6c6530689
// Migrate to v8 - Part 3: https://www.loom.com/share/c1bf20e18d8a42f8a55f8a28ab605148

// const id = 'Examples/Playground'

let components = createPlateUI({
    //   [ELEMENT_EXCALIDRAW]: ExcalidrawElement,
    // customize your components by plugin key
})
components = withStyledPlaceHolders(components)
// components = withStyledDraggables(components)

export const MarkdownNotesReadOnly = ({ id, value }: { id: string, value: string }) => {
//USED FOR CREATING SLANT OBJECT
    // const [parsedMDValue, setParsedMDValue] = useState<any>(undefined)
    // console.log(value, 'md VALUE');

    // // const unifiedLocal: any = unified as any
    // console.log('STRINIFIED SLANt::', JSON.stringify(parsedMDValue));

    // const parseMD = async () => {
    //     const out = await unified()
    //         .use(markdown)
    //         .use(slate)
    //         .process(madk);
    //     console.log(out.result, "out");
    //     setParsedMDValue(out.result)

    // }

    // useEffect(() => {
    //     parseMD()

    // }, [])
//USED FOR CREATING SLANT OBJECT

    let valueToDisplay = []
    try {
        valueToDisplay = JSON.parse(value)

    } catch (error) {

        console.error('Wrong notes format')

    }

    const plugins = createPlugins(
        [
            createParagraphPlugin(),
            createBlockquotePlugin(),
            createTodoListPlugin(),
            createHeadingPlugin(),
            createImagePlugin(),
            createHorizontalRulePlugin(),
            createLinkPlugin(),
            createListPlugin(),
            createTablePlugin(),
            createMediaEmbedPlugin(),
            //   createExcalidrawPlugin(),
            // createCodeBlockPlugin(),
            createAlignPlugin(CONFIG.align),
            createBoldPlugin(),
            createCodePlugin(),
            createItalicPlugin(),
            createHighlightPlugin(),
            createUnderlinePlugin(),
            createStrikethroughPlugin(),
            createSubscriptPlugin(),
            createSuperscriptPlugin(),
            createFontColorPlugin(),
            createFontBackgroundColorPlugin(),
            createFontSizePlugin(),
            createKbdPlugin(),
            createNodeIdPlugin(),
            createDndPlugin(),
            createIndentPlugin(CONFIG.indent),
            createAutoformatPlugin(CONFIG.autoformat),
            createResetNodePlugin(CONFIG.resetBlockType),
            createSoftBreakPlugin(CONFIG.softBreak),
            createExitBreakPlugin(CONFIG.exitBreak),
            createNormalizeTypesPlugin(CONFIG.forceLayout),
            createTrailingBlockPlugin(CONFIG.trailingBlock),
            createSelectOnBackspacePlugin(CONFIG.selectOnBackspace),
            createComboboxPlugin(),
            createMentionPlugin(),
            createDeserializeMdPlugin(),
            createDeserializeCsvPlugin(),
            createDeserializeDocxPlugin(),
            createJuicePlugin(),
            createDeserializeMdPlugin(),
        ],
        {
            components,
        }
    )

    return (
        <>

            {valueToDisplay && (<DndProvider backend={HTML5Backend}>
                <Plate
                    id={id}
                    editableProps={CONFIG.editableProps}
                    // initialValue={parsedMDValue}
                    plugins={plugins}
                    value={valueToDisplay}
                >

                </Plate>
            </DndProvider>)}
        </>
    )
}



// const madk = `

// **Annnnglisy Lesona 1: Mahazo teny Anglisy ve ianao?**

// John: _Excuse me, do you understand English?_

// Jane: No sir, I don’t understand English.

// John: I understand Malagasy a little.

// Jane: Are you British?

// John: Yes, miss.

  

// **Voambolana tsara ho fantatra:** 

  

// **Excuse me /ɪkskj****uː****z/ -/mi/ =** Azafady

// Amin’ny teny Anglisy dia misy teny roa midika hoe azafady. Ny iray izay hitantsika eto dia ny hoe excuse me izay ampiasaina rehefa te hampahafantatra olona fa te hiresaka aminy isika na misy hilantsika azy ireo. Ohatra rehefa te andoa vola any amina hotely isika ohatra dia manao hoe azafady na rehefa mandika olona koa ka mangata-dalana dia manao azafady.

  

// Ny faharoa dia “sorry” izay ampiasaina kosa rehefa avy manao fahadisoana isika. amin’ny teny Gasy izy mety hitovy amin’ny hoe azafady na koa hoe miala tsiny fa amin’ny teny Anglisy koa dia tena teny roa samihafa.

  

// **English /****ɪ****ŋglɪʃ/=** Anglisy (teny na olona) 

  

// **Understand /****ʌ****ndəʳst****æ****nd/=** Mahazo 

// Toy ny amin’ny hoe mahazo resaka anefa fa tsy hoe mahazo vola akory.

  

// **You /j****uː/****\=** Ianao

  

// **I /****aɪ/****\=** Izaho

  

// **No /n****oʊ/****\=** Tsia

  

// **Yes /j****e****s/=** Eny 

  

// **Sir /s****ɜː****/=** Tompoko/Ramose

// Ampiasaina mba hanehoana fanajana amina lehilahy lehibe, dia ny lehilahy lehibe ihany ka amin’ny Malagasy dia mety sady hidika hoe ramose izy no midika koa hoe tompoko.

  

// Raha haneho fanajana ho amin’ny andriambavilanitra kosa dia misy sokajy roa:

// **Miss** raha toa ka zatovovavy no hiresahana ary **Madam** na Misses kosa raha efa ramatoa na madama.

  

// **Do /duː/ (Auxilliaire)**

// Amin’ny teny Anglisy dia tena manan-daja be ito teny kely atao hoe auxilliaire ito. Misy karazany maro izy amin’ny teny anglisy toy ny : are, is, will, shall, might sns…

  

// Eto anefa ny ampiasaina dia ny “do” entina ametrahana fanontaniana miandry valiny eny na tsia avy amina olona iray. Ary hohazavaina amin’ny manaraka ireo auxilliaires sasany rehefa ampiasaintsika anatina fehezan


// **A little /eɪ/ - /lɪtəl/=** Kely (un peu amin’ny teny Frantsay)- Adverbe

  

// **British /br****ɪ****tɪʃ/=** Britanika 

// Anarana hilazana ny olona avy Grande Bretagne.

// `

