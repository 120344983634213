import { Link } from "react-router-dom";
export default function RemoveAccount() {


    return (
        <div style={{ background: '#f3a81d', color: 'white', padding: '30px' }}>
            <Link to="/">BACK TO MAINSCREEN</Link>
            <h1>Account removal instructions</h1>

            <p>To remove your account simply send as an email with login user name or email address</p>

            <h2 color="red"><a href="mailto:junakowicz@gmail.com?subject=Remove account">Remove Account</a></h2>

        </div>)
}