import { RemoteLessonItemDoc } from "./remoteDB";
import { localDB, LocalLessonItemDoc } from "./localDB";

export const initSyncWithRemote: (
  remote: RemoteLessonItemDoc[],
  local: LocalLessonItemDoc[]
) => Promise<LocalLessonItemDoc[]> = async (remote, local) => {
  console.log("initSyncWithRemote");
  console.log("initSyncWithRemote remote", remote);

  //REMOVE
  // check if lesson id exists on both
  // if not, remove from local
  const lessonsToRemove = local.filter((localLesson) => {
    return !remote.some((remoteLesson) => {
      return remoteLesson.id === localLesson.id;
    });
  });

  console.log("lessonsToRemove::::::", lessonsToRemove);

  if (lessonsToRemove.length) {
    console.info("REMOVING stalled local lessons::::::", lessonsToRemove);
    await localDB.lessons.bulkDelete(lessonsToRemove.map((l) => l.id));
  }
  //ADD
  // check if lesson id exists on both
  // if not, add to local
  const newLessonsToAdd = remote.filter((remoteLesson) => {
    return !local.some((localLesson) => {
      return remoteLesson.id === localLesson.id;
    });
  });
  console.log("lessonsToAdd::::::", newLessonsToAdd);
  if (newLessonsToAdd.length) {
    const newMappedToLocal: LocalLessonItemDoc[] = newLessonsToAdd.map(
      (lesson) => {
        return {
          ...lesson,
          recording: null,
          availableOffline: false,
          completed: false,
          progress: 0,
          notesUser: [{ children: [{ text: "" }] }],
        };
      }
    );
    console.info("ADDING new remote to local lessons::::::", newLessonsToAdd);
    await localDB.lessons.bulkPut(newMappedToLocal);
  }
  // UPDATE
  // const mappedToLocal: LocalLessonItemDoc[] =[]
  // check if each lessson ver is the same
  // if no replace fields without removing the notes

  const localToUpdate = remote.filter((remoteLesson) => {
    const localMatch = local.find(
      (localLesson) => localLesson.id === remoteLesson.id
    );
    console.log("remoteMatch::::::", localMatch);
    return localMatch?.ver !== remoteLesson.ver;
  });
  console.log("localToUpdate::::::", localToUpdate);
  const localToUpdateReset: LocalLessonItemDoc[] = localToUpdate.map(
    (localLesson) => {
      return {
        ...localLesson,
        recording: null,
        availableOffline: false,
        completed: false,
        progress: 0,
        notesUser: [{ children: [{ text: "" }] }],
      };
    }
  );

  if (localToUpdateReset.length) {
    console.info("UPDATING/RESETTING localLesson::::::", localToUpdateReset);
    await localDB.lessons.bulkPut(localToUpdateReset, { allKeys: true });
  }
  // console.log({ localMapped: mappedToLocal });

  // await localDB.lessons.bulkPut(mappedToLocal);
  //TODO check adding new
  //TODO check add, removing, update at the same time
  //TODO check if we need to return mappedToLocal below
  const mappedToLocal: LocalLessonItemDoc[] = [];
  return mappedToLocal;
};

export const getLocalLessons: () => Promise<
  LocalLessonItemDoc[]
> = async () => {
  return await localDB.lessons.toArray();
};

export const isUpToDate: (
  remote: RemoteLessonItemDoc[],
  local: LocalLessonItemDoc[]
) => boolean = (remote, local) => {
  const remoteNum = remote && remote.length;
  const localNum = local && local.length;
console.log("remoteNum::::::", remoteNum);
// there must be a problem with the connection ...
if (remoteNum === 0) {
  return true;
}
  const lenghtAndExistsSame = remoteNum === localNum;
  console.log("lenghtAndExistsSame::::::", lenghtAndExistsSame);

  const areVersionsTheSame = remote.reduce((acc, remoteLesson) => {
    const localHasIdAndVerSame = local.some((localLesson) => {
      const sameId = localLesson.id === remoteLesson.id;
      const sameVer = localLesson.ver === remoteLesson.ver;
      return sameId && sameVer;
    });

    return acc && localHasIdAndVerSame;
  }, true);

  console.log("areVersionsTheSame::::::", areVersionsTheSame);
  return lenghtAndExistsSame && areVersionsTheSame;
};
