
import CloseIcon from '@mui/icons-material/Close';

import { IconButton, Snackbar } from "@mui/material";
import { useState } from "react";
import { onMessageListener } from "..";

export const ReactNotificationComponent = () => {

    const [notification, setNotification] = useState({ title: "", body: "" });
    const [show, setShow] = useState(false);


    const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        console.log('reason::::::', reason);
        if (reason === 'clickaway') {
            return;
        }

        setShow(false);
    };

    const action = (
        <>

            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </>
    );
    console.log(notification, "notificatiob");
    onMessageListener()
        .then((payload: any) => {
            console.log("message paybload", payload);
            setShow(true);
            setNotification({
                title: payload.notification.title,
                body: payload.notification.body,
            });
        })
        .catch((err) => console.log(" message failed: ", err));

    return <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: "center" }}
        open={show}
        title={notification.title}
        onClose={handleClose}
        message={notification.title}
        key={notification.title + notification.body}
        action={action}
    />
};