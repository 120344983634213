import * as React from 'react';
import { getPhrasesCategories, PhraseCategory, getPhrases, Phrase } from "../api/malen-be";
import { useAuthState } from 'react-firebase-hooks/auth';
import { getAuth, User } from 'firebase/auth';
import { useTranslation } from 'react-i18next';

import { DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
// @ts-ignore
import { Dialog, Card, CardContent, ListItem, ListItemGroup, Subtitle2, CardAction, Button } from 'ui-neumorphism'

//TODO add offline at least for the audio
// add audio

export default function PhrasesCategories() {
  const [user] = useAuthState(getAuth());
  const [categories, setCategories] = React.useState<PhraseCategory[]>([]);
  const [phrases, setPhrases] = React.useState<Phrase[]>([]);
  const [activeCategory, setActiveCategory] = React.useState();
  const [alertModalOpen, setAlertModalOpen] = React.useState(false);
  const [selectedPhraseId, setSelectedPhraseId] = React.useState('');
// @ts-ignore
// eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [t, i18n] = useTranslation('common');

  const getCategories = async (user: User | null | undefined) => {
    const idToken = await user?.getIdToken() || ''
    const categoriesData = await getPhrasesCategories(idToken)
    setCategories(categoriesData)
  }

  const getActiveCategoryPhrases = async (user: User | null | undefined, catId: string) => {
    const idToken = await user?.getIdToken() || ''
    const phrasesResponse = await getPhrases(idToken, catId)
    setPhrases(phrasesResponse)
    document.querySelector('#phrases-container')?.scrollIntoView({
      behavior: 'smooth'
    });
  }
  const handleAlertModalClose = () => {
    setAlertModalOpen(false)
  }
  const handleAlertModalOpen = () => {
    setAlertModalOpen(true)
  }

  React.useEffect(() => {
    if (user) {
      getCategories(user)
    }
  }, [user])

  React.useEffect(() => {
    if (activeCategory !== undefined) {
      const activeCategoryId = categories?.[activeCategory as number]?.id
      getActiveCategoryPhrases(user, activeCategoryId)
    }
  }, [activeCategory, categories, user])

  const onPlay = (id: string) => {
    setSelectedPhraseId(id)
    handleAlertModalOpen()
  }

  return (
    <>
      <Card
        className='d-flex align-center justify-center flex-wrap'
        style={{ marginTop: '18px' }}
      >
        {Boolean(categories?.length) && <ListItemGroup
          link
          value={activeCategory}
          style={{
            padding: '8px',
            maxWidth: '100vw'
          }}
          onChange={({ active }: any) => {
            setActiveCategory(active)
          }}
        >
          {categories?.map((cat) => <ListItem title={cat.name[i18n.language]} key={cat.id} />)}
        </ListItemGroup>}
      </Card>
      {Boolean(phrases?.length) && <div
        // @ts-ignore
        id={'phrases-container'}
        className='d-flex align-center justify-center flex-wrap'
        style={{
          padding: '8px',
          width: '100vw'
        }}
      >
        {phrases?.map((ph) => <PhraseCard key={ph.id} phrase={ph} onPlay={onPlay} />)}
      </div>
      }
      <AlertDialog handleCloseModal={handleAlertModalClose} phraseId={selectedPhraseId} open={alertModalOpen} />
    </>
  );
}


const PhraseCard = ({ phrase, onPlay }: { phrase: Phrase, onPlay: (id: string) => void }) => {
  const handlePlay = React.useCallback(() => {
    onPlay(phrase.id)
  }, [phrase, onPlay])


  return <Card>
    <CardContent style={{ textAlign: 'start' }}>
      <Subtitle2 style={{ paddingTop: '24px' }}>
        EN: {phrase.name.en}
      </Subtitle2>
      <Subtitle2>
        MG: {phrase.name.mg}
      </Subtitle2>
    </CardContent>
    <CardAction>
      <Button text color='var(--primary)' onClick={handlePlay}>
        PLAY
      </Button>
    </CardAction>
  </Card>
}


const AlertDialog = ({ phraseId, open, handleCloseModal }: { phraseId: string, open: boolean, handleCloseModal: () => void }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [t, i18n] = useTranslation('common');

  return <Dialog visible={open} onClose={handleCloseModal}>
    <DialogTitle>{ }</DialogTitle>
    <DialogContent>
      <DialogContentText>

        {t('coming_soon')}
      </DialogContentText>
      <DialogContentText>

        {t('recording_unavailable')}
      </DialogContentText>
      <DialogContentText>

        {phraseId}
      </DialogContentText>

    </DialogContent>
    <DialogActions>
      <Button onClick={handleCloseModal}>{t('ok')}</Button>
    </DialogActions>
  </Dialog>

}