
// export const MALEN_BE = "http://localhost:3001";
export const MALEN_BE = "https://malen-be.onrender.com"

export interface PhraseCategory {
  id: string;
  name: { [lang: string]: string };
}
export interface Phrase {
  id: string;
  name: { [lang: string]: string };
}

export const downloadLesson = async (
  userToken: string,
  lessonRecId: string
) => {
  console.log("downloadLesson::::::");
  const out = fetch(`${MALEN_BE}/lessons`, {
    method: "post",
    headers: new Headers({
      Authorization: `Bearer ${userToken}`,
      "Content-Type": "application/json",
    }),
    body: JSON.stringify({
      lessonRecId,
    }),
  });

  console.log("out::::::", out);
};
export const getAllUsers = async (userToken: string) => {
  console.log("getAllUsers::::::");
  const out = await fetch(`${MALEN_BE}/users`, {
    method: "get",
    headers: new Headers({
      Authorization: `Bearer ${userToken}`,
      "Content-Type": "application/json",
    }),
  });
  console.log("out::::::", out);
  return await out.json();
};
export const getPhrasesCategories: (
  userToken: string
) => Promise<PhraseCategory[]> = async (userToken) => {
  console.log("getPhrasesCategories::::::");
  const out = await fetch(`${MALEN_BE}/phrases/categories`, {
    method: "get",
    headers: new Headers({
      Authorization: `Bearer ${userToken}`,
      "Content-Type": "application/json",
    }),
  });
  console.log("out::::::", out);
  return await out.json();
};

export const getPhrases: (
  userToken: string,
  catId: string
) => Promise<Phrase[]> = async (userToken, catId) => {
  console.log("getPhrases::::::");

  const out = await fetch(`${MALEN_BE}/phrases`, {
    method: "post",
    headers: new Headers({
      Authorization: `Bearer ${userToken}`,
      "Content-Type": "application/json",
    }),
    body: JSON.stringify({ catId }),
  });
  console.log("out::::::", out);
  return await out.json();
};