import { useAuthState } from "react-firebase-hooks/auth";
import { getAuth } from 'firebase/auth';
import { useEffect, useState } from "react";
import { getAllUsers } from "../../api/malen-be";
import { Card } from "ui-neumorphism";
import { getLessonsUnlocked, updateLessonsUnlocked, createUserInfo, NO_DOC } from "../../database/remoteDB";
import * as React from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { Button, Chip, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from "@mui/material";



const columns: GridColDef[] = [
    { field: 'id', headerName: 'Lesson ID', width: 70 },

];
const userListcolumns: GridColDef[] = [
    { field: 'uid', headerName: 'UID', minWidth: 250 },
    { field: 'displayName', headerName: 'displayName', minWidth: 250 },
    { field: 'email', headerName: 'email', minWidth: 250, resizable: true },
    { field: 'creationTime', headerName: 'creationTime', minWidth: 250, resizable: true },
    { field: 'lastSignInTime', headerName: 'lastSignInTime', minWidth: 250 },

];

interface UserData  {

    creationTime: string,
    displayName: string,
    email: string,
    lastSignInTime: string,
    role: string,
    uid: string,

}
const getFlatFromSelectionModel = (selectionModel: { [key: number]: string[] }) => Object.values(selectionModel).reduce((all: any, level) => [...all, ...level], [])


export default function Admin() {
    const [user, error] = useAuthState(getAuth());
    const [userList, setUserList] = useState<UserData[]>();
    const [selectedUser, setSelectedUser] = useState<UserData>();
    const [initialUnlockedLessons, setSelectedUserUnlockedLessons] = useState(['']);
    const [selectionModel, setSelectionModel] = useState<{ [key: number]: string[] }>({});
    const [allLessons, setAllLessons] = useState<{ id: string; unlocked: boolean; }[][]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [changeConfirmOpen, setChangeConfirmOpen] = React.useState(false);
    const handleOpenChangeConfirm = () => setChangeConfirmOpen(true);
    const handleCloseChangeConfirm = () => setChangeConfirmOpen(false);


    const [createUserOpen, setCreateUserOpen] = React.useState(false);
    const handleCloseCreateUser = () => {
        setCreateUserOpen(false)
        backToAll()
    };

console.log('Admin.tsx  >>> userList::::', userList);
    console.log('allLessons::::::', allLessons);

    console.log('error::::::', error);
    const getUsers = async (loggedUser: any) => {
        setIsLoading(true)
        const idToken = await loggedUser?.getIdToken() || '';
        console.log('idToken::::::', idToken);
        const allUserList = await getAllUsers(idToken)
        console.log('allUserList::::::', allUserList);
        setUserList(allUserList?.users)
        setIsLoading(false)
    }

    const handleGetDetails = async (user: UserData) => {

        setIsLoading(true)
        setSelectedUser(user)
        console.log('handleGetDetails user::::::', user.displayName);

        const unlockedLessons = await getLessonsUnlocked(user.uid)
        if (unlockedLessons.length === 1 && unlockedLessons[0] === NO_DOC) {

            setCreateUserOpen(true)
            setIsLoading(false)
            return
        }

        const userLessons = unlockedLessons || ['']
        console.log('unlockedLessons::::::', userLessons);
        processUnlockedLessons(userLessons)
        setIsLoading(false)
    }


    const processUnlockedLessons = (unlockedLessons: string[]) => {
        console.log('unlockedLessons::::::', unlockedLessons);
        setSelectedUserUnlockedLessons(unlockedLessons)
        let initialModel: { [key: number]: string[] } = {}
        const allLessons = Array.from(Array(5).keys())
            .map((level) => {
                const levelNum = level + 1
                const lessons = Array.from(Array(30).keys()).map((lessonNum) => {
                    const lessonId = `l${level + 1}l${lessonNum + 1}`
                    const isUnlocked = unlockedLessons.includes(lessonId)
                    console.log('isUnlocked::::::', isUnlocked);
                    // const existingLevelLessons = selectionModel[levelNum]

                    if (isUnlocked) {

                        const existingLessons = initialModel[levelNum] || []
                        const updatedLevelLessons = [...existingLessons, lessonId]
                        initialModel = { ...initialModel, [levelNum]: updatedLevelLessons }
                        // console.log('newModel::::::', newModel);

                    }
                    return { id: lessonId, unlocked: isUnlocked }
                })
                return lessons

            })
        setAllLessons(allLessons)
        setSelectionModel(initialModel)
        console.log('initialModel::::::', initialModel);


    }


    const backToAll = () => {
        setSelectedUser(undefined)
        setSelectedUserUnlockedLessons([])
        setSelectionModel({})
    }
    const onSelectionModelChange = (v: string[], levelNum: number) => {
        console.log('v::::::', v);
        // if (v.length) {

        setSelectionModel({ ...selectionModel, [levelNum]: v })
        // }
    }


    const handleSaveChanges = React.useCallback(async () => {
        console.log('initialUnlockedLessons::::::', initialUnlockedLessons);
        console.log('selectionModel::::::', selectionModel);
        const unlockedToSave = getFlatFromSelectionModel(selectionModel)

        //TODO save to database 
        console.log('unlockedToSave::::::', unlockedToSave);
        setIsLoading(true)
        await updateLessonsUnlocked(selectedUser?.uid || '', unlockedToSave)

        setIsLoading(false)
        handleCloseChangeConfirm()

    }, [initialUnlockedLessons, selectionModel, selectedUser?.uid])


    const handleCreateUser = React.useCallback(async () => {

        console.log('handleCreateUser selectedUser?.uid::::::', selectedUser?.uid);
        setIsLoading(true)
        const createResp = await createUserInfo(selectedUser?.uid || '')
        processUnlockedLessons(createResp)
        setIsLoading(false)
        setCreateUserOpen(false)

    }, [selectedUser?.uid])


    useEffect(() => {
        console.log('useEffect::::user change ;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;::', useEffect);


        if (user) {
            getUsers(user)

        }
    }, [user])

    console.log('selectedUser::::::', selectedUser);
    console.log('selectedUserUnlockedLessons::::::', initialUnlockedLessons);
    console.log('selectionModel::::::', selectionModel);
    const shouldShowManageScreen = selectedUser?.uid && Boolean(initialUnlockedLessons.length) && initialUnlockedLessons[0] !== '';
    console.log('shouldShowManageScreen::::::', shouldShowManageScreen);
    return (<>
        {isLoading && <CircularProgress size={180} color="success" />}
        {!selectedUser?.uid &&
            (<div style={{ height: '100vh', width: '100%' }}>
                <DataGrid
                    rows={userList || []}
                    columns={userListcolumns}
                    pageSize={30}
                    rowsPerPageOptions={[30]}
                    getRowId={(row) => row.uid}
                    onRowClick={(v) => {

                    console.log('row::::::', v.row);
                    handleGetDetails(v.row as unknown as UserData)
                }}
            />
        </div>)

        }
        {shouldShowManageScreen &&
            (
                <><Card>

                    <p onClick={backToAll}>back to all users</p>
                    <p>{user?.displayName}</p>

                    <h2>Editing {selectedUser.email} {selectedUser?.displayName} {selectedUser.uid}</h2>
                <Button onClick={handleOpenChangeConfirm}>Save changes</Button>

                </Card>
                    {allLessons.map((_, level) => {
                        const levelNum = level + 1;
                        const model: string[] = selectionModel?.[levelNum] || []
                        return (
                            <>

                                <h2>Level {levelNum}</h2>

                                <div style={{ height: 400, width: '100%' }}>
                                    <DataGrid
                                        rows={allLessons[level]}
                                        columns={columns}
                                        pageSize={30}
                                        rowsPerPageOptions={[30]}
                                        checkboxSelection
                                        onSelectionModelChange={
                                            (v) => onSelectionModelChange(v as string[], levelNum)
                                        }
                                        selectionModel={model}
                                    />
                                </div>
                            </>)

                    })}

                </>

            )


        }
        <div>

            <Dialog open={changeConfirmOpen} onClose={handleCloseChangeConfirm}>
                <DialogTitle> Please check that the changes are correct and confirm</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        After confirmation changes will be commited to the database, to cancel all the changes refresh the page.
                    </DialogContentText>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        <div>
                            Currently unlocked: {initialUnlockedLessons.map((lesson) => {
                                return <Chip key={lesson} label={lesson} variant="outlined" />

                            })}

                        </div>
                        Updated unlocked: {getFlatFromSelectionModel(selectionModel).map((lesson) => {
                            return <Chip key={lesson} label={lesson} />

                        })}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseChangeConfirm}>Cancel</Button>
                    <Button onClick={handleSaveChanges}>Confirm</Button>
                </DialogActions>
            </Dialog>

            <Dialog open={createUserOpen} onClose={handleCloseCreateUser}>
                <DialogTitle>Looks like user does not exist yet !</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Confirm to create one, once successful select it again to edit
                    </DialogContentText>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        <div>
                            <p> display name: {selectedUser?.displayName}</p>
                            <p> email: {selectedUser?.email}</p>
                            <p> uid: {selectedUser?.uid}</p>
                            <p> creationTime: {selectedUser?.creationTime}</p>
                            <p> lastSignInTime: {selectedUser?.lastSignInTime}</p>

                        </div>
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseCreateUser}>Cancel</Button>
                    <Button onClick={handleCreateUser}>Create</Button>
                </DialogActions>
            </Dialog>
        </div></>

    );
}
