import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { Link } from "react-router-dom";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material';
import { registerUser, logInWithEmailAndPassword, signInWithPopupFacebook, signInWithPopupGoogle } from '../auth/authorization';
import { getAuth, signOut } from 'firebase/auth';
import { useTranslation } from 'react-i18next';

enum MODE {
  LOGIN,
REGISTER
}


export default function MenuAppBar({ user }: any) {
  const [t] = useTranslation('common');
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [email, setEmail] = React.useState<string>('');
  const [mode, setMode] = React.useState<MODE>(MODE.LOGIN);
  const [pass, setPass] = React.useState<string>('');

  React.useEffect(()=>{
    handleCloseModal()

  },[user])

  const login = () => {
    logInWithEmailAndPassword(email, pass);
  };

  const logout = () => {
    signOut(getAuth());
    handleClose()
  };


  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const onEmailChange = (event: any) => {
    setEmail(event.currentTarget.value);
  };
  const onPassChange = (event: any) => {
    setPass(event.currentTarget.value);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

    const [open, setOpen] = React.useState(false);

  const handleRegister = () => {
    registerUser(email, pass)
  };

  
  const handleClickOpenRegister = () => {
    setMode(MODE.REGISTER)
    setOpen(true);
  };
  const handleClickOpenLogin = () => {
    setMode(MODE.LOGIN)
    setOpen(true);
  };

  
  const handleCloseModal = () => {
    setOpen(false);
  };


  const loginOrRegister = MODE.LOGIN === mode ? t('login') : t('register')
  return (
      <>
      <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
          <Toolbar>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1, textAlign: 'left' }}>
            Malen core
          </Typography>
          {user ? (
            <div>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                         <Typography variant="overline" component="div" sx={{ flexGrow: 1 }}>
                         {user.email } {user?.displayName}
          </Typography> <AccountCircle />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                  <MenuItem><Link to="/profile">Profile</Link></MenuItem>
                <MenuItem onClick={logout}>Log out</MenuItem>
              </Menu>
            </div>
            ) : (<><Button color="inherit" onClick={handleClickOpenLogin}>{t('login')}</Button>
              <Button color="inherit" onClick={handleClickOpenRegister}> {t('register')}</Button></>)}
            <LanguagePicker />
        </Toolbar>
      </AppBar>
    </Box>
        
        <Dialog open={open} onClose={handleCloseModal}>
        <DialogTitle>{loginOrRegister}</DialogTitle>
          <DialogContent>
            <DialogContentText>
            {loginOrRegister} to the app
            </DialogContentText>
            <DialogContentText>
            {loginOrRegister}
            </DialogContentText>
            <Button onClick={signInWithPopupFacebook}>Facebook</Button>
          <Button onClick={signInWithPopupGoogle}>Google</Button>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Email Address"
              type="email"
              fullWidth
              variant="standard"
              onChange={onEmailChange}
              value={email}
            />
             <TextField
              autoFocus
              margin="dense"
              id="pass"
              label="Password"
              type="password"
              fullWidth
              variant="standard"
              onChange={onPassChange}
              value={pass}
            />

          </DialogContent>
          <DialogActions>
          <Button onClick={handleCloseModal}>{t('cancel')}</Button>
          <Button onClick={MODE.LOGIN === mode ? login : handleRegister}>{loginOrRegister}</Button>
          </DialogActions>
        </Dialog>
        
</>
  );
}

function LanguagePicker() {
  /* eslint-disable @typescript-eslint/no-unused-vars */
  const [t, i18n] = useTranslation('common');
  return <div>

    <Button variant="contained" color="success" onClick={() => i18n.changeLanguage('mg')}>mg</Button>
    <Button variant="contained" color="primary" onClick={() => i18n.changeLanguage('en')}>en</Button>
  </div>
}