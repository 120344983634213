
import { useEffect, useRef, useState, useCallback } from 'react';
import { Link } from "react-router-dom";
import logo2 from './assets/drawing.svg';
import appHome from './assets/help/app-home.jpg';
import appLogin from './assets/help/app-login.jpg';
import appMenu from './assets/help/app-menu.jpg';
import appLessons from './assets/help/app-lessons.png';
import downloadBtn from './assets/help/download-btn.png';
import playBtn from './assets/help/play-btn.png';
import './App.css';
import MenuAppBar from './components/MenuAppBar';
import Typography from '@mui/material/Typography';
import OnlineStatusBar from './components/OnlineStatusBar';
import { getLocalLessons, isUpToDate, initSyncWithRemote } from './database/mergedDB';
import LessonList from './components/LessonList';
import AudioPlayer from './components/AudioPlayer';
import { useAuthState } from 'react-firebase-hooks/auth';
import { getAuth } from 'firebase/auth';
import { ReactJkMusicPlayerAudioInfo, ReactJkMusicPlayerAudioListProps } from 'react-jinke-music-player';
import { localDB } from './database/localDB';
import { LocalLessonItemDoc } from './database/localDB';
import { useIsOnline } from './hooks';
import { ReactNotificationComponent } from './components/ReactNotificationComponent';
import { getLessons as remoteGetLessons } from "./database/remoteDB";
import {
  ToggleButton, ToggleButtonGroup,
} from 'ui-neumorphism'
import 'ui-neumorphism/dist/index.css'
import { overrideThemeVariables } from 'ui-neumorphism'
import { useTranslation } from 'react-i18next';
import PhrasesCategories from './components/PhrasesCategories';
import { useServiceWorker } from './hooks/useServiceWorker';


import { DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
// @ts-ignore
import { Dialog, Card, Button } from 'ui-neumorphism'


var pjson = require('../package.json');
enum SECTIONS {
  LESSONS = 'Lessons',
  PHRASES = 'Phrases'

}

function App() {
  const { t } = useTranslation('common');
  const [section, setSection] = useState<SECTIONS>(SECTIONS.LESSONS)
  const [lessons, setLessons] = useState<LocalLessonItemDoc[]>([])
  const [playingRecId, setPlayingRecId] = useState<string>('')
  const [isHelpOpen, setIsHelpOpen] = useState(false)
  const [tracks, setTracks] = useState<ReactJkMusicPlayerAudioListProps[]>([])
  const [playIndex, setPlayIndex] = useState<number>(0)
  const [showRefreshDialog, setShowRefreshDialog] = useState<boolean>(false)
  const isOnline = useIsOnline()
  // App.tsx
  const { waitingWorker, showReload, reloadPage } = useServiceWorker();// decides when to show the toast


  const handleRefresh = () => {
    console.log('handleRefresh::::::', handleRefresh);
    reloadPage()
    setShowRefreshDialog(false)
  }

  useEffect(() => {

    console.log('showReload::::::', showReload);
    console.log('waitingWorker::::::', waitingWorker);
    if (showReload && waitingWorker) {

      setShowRefreshDialog(true)
    } else {
      setShowRefreshDialog(false)
    };
  }, [waitingWorker, showReload]);

  console.log('isOnline::::::', isOnline);
  const eA: any = useRef()

  const [user, error] = useAuthState(getAuth());
  console.log('user::::::', user);
  console.log(error, 'user, loading, error');
  const populateLessons = useCallback(() => {

    const innerAsync = async () => {
      if (isOnline) {
        const remote = await remoteGetLessons();
        const local = await getLocalLessons();
        const shouldSync = !isUpToDate(remote, local)
        console.log('shouldSync::::::', shouldSync);
        if (shouldSync) {
          await initSyncWithRemote(remote, local)
        }

      }
      const lessons = await getLocalLessons()

      setLessons(lessons)
    }

    innerAsync()
  }, [isOnline,])

  useEffect(() => {
    populateLessons()
  }, [user, isOnline, populateLessons])

  useEffect(() => {
    overrideThemeVariables({
      '--light-bg': '#f3a81d',
      '--light-bg-dark-shadow': '#e19707',
      '--light-bg-light-shadow': '#fec14e',
      '--dark-bg': '#292E35',
      '--dark-bg-dark-shadow': '#21252a',
      '--dark-bg-light-shadow': '#313740',
      '--primary': '#407637',
      '--primary-dark': '#4526f9',
      '--primary-light': '#809757',

    })
  }, [])


  const playerInstance: any = useRef()
  if (!eA.current) {

    eA.current = playerInstance?.current?.getEnhanceAudio()

  }

  const playRec = async (lesson: LocalLessonItemDoc) => {
    const { id } = lesson
    console.log(id, "---------playRec ------ ");

    const lessonFromIDB = await localDB.lessons.get({ id });
    // console.log({ lessonFromIDB });
    const hasRecording = Boolean(lessonFromIDB?.recording?.size)
    if (hasRecording) {

      console.log(lessonFromIDB?.title, 'has recording ')
      const fileMp3 = lessonFromIDB?.recording && window.URL.createObjectURL(lessonFromIDB?.recording)
      console.log(fileMp3);
      playerInstance?.current?.audio?.clear()


      if (lessonFromIDB?.recId === playingRecId) {
        console.log('recId ===playingRecId', playerInstance?.current?.audio);

        playerInstance?.current?.audio?.pause()
        setPlayingRecId('')
      } else {

        // TODO fix resume
        setTracks([{ name: lessonFromIDB?.title, musicSrc: fileMp3 || '' }])
        setPlayingRecId(lessonFromIDB?.recId || '')
        setPlayIndex(0)
      }
    }
  }


  const onAudioPlayTrackChange = (currentPlayId: string,
    audioLists: Array<ReactJkMusicPlayerAudioListProps>,
    audioInfo: ReactJkMusicPlayerAudioInfo) => {
    console.log({ currentPlayId }, 'onAudioPlayTrackChange', { audioInfo });

  }
  const onSectionSelect = ({ active }: { active: SECTIONS }) => {
    setSection(active)
  }
  const handleCloseHelp = () => {
    setIsHelpOpen(false)
  }
  const handleOpenHelp = () => {
    setIsHelpOpen(true)
  }



  console.log(tracks, "TRACKS MAIN", { playingRecId });

  return (
    <div className="App">
      <MenuAppBar user={user} />
      <OnlineStatusBar />
      <ReactNotificationComponent />
      {!user && <Card
        flat
        className='mt-4 d-flex flex-wrap align-center justify-center'
      >
        <h3>{t('register_login')}</h3>
      </Card>}
      <Button onClick={handleOpenHelp}   style={{
          color: '#54ddff',
        }}>
        {t('help_btn')}

          </Button>
      <header className="App-header">
        <Card rounded
          width={120}
          height={120} elevation={0}
          style={{ borderRadius: '20px', margin: '20px', padding: '20px' }}>
          <img src={logo2} className="App-logo" alt="logo" /></Card>
        <Card
          flat
          className='mt-4 d-flex flex-wrap align-center justify-center'
        >

          <ToggleButtonGroup
            value={section}
            mandatory

            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            // @ts-ignore
            text={false}
            color='var(--primary)'
            onChange={onSectionSelect}
          >

            <ToggleButton style={{ padding: '10px' }} value={SECTIONS.LESSONS} outlined>
              {SECTIONS.LESSONS}
            </ToggleButton>
            <ToggleButton style={{ padding: '10px' }} value={SECTIONS.PHRASES} outlined>
              {SECTIONS.PHRASES}
            </ToggleButton>

          </ToggleButtonGroup>
        </Card>
        {section === SECTIONS.PHRASES ?
          (<>
            <PhrasesCategories />
          </>)
          :
          (<>
            {user && <LessonList data={lessons} heading={t('lessons.levelOne')} playRec={playRec} playingRecId={playingRecId} />}
            {user && <AudioPlayer tracks={tracks} onAudioPlayTrackChange={onAudioPlayTrackChange} playerInstance={playerInstance} playIndex={playIndex} />}

          </>)}

        <a href={"https://www.facebook.com/profile.php?id=100093386623113"} target={"_blank"} rel="noreferrer">Facebook Malen</a> 
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          {pjson.version} <Link to='privacy-policy'>Privacy Policy</Link>
        </Typography>

      </header>
      <RefreshDialog handleCloseModal={() => setShowRefreshDialog(false)} handleRefresh={handleRefresh} open={showRefreshDialog} />
      <Help open={isHelpOpen} handleCloseHelp={handleCloseHelp}/>
    </div>

  );
}

export default App;



const RefreshDialog = ({ open, handleCloseModal, handleRefresh }: { open: boolean, handleCloseModal: () => void, handleRefresh: () => void }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [t, i18n] = useTranslation('common');

  return <Dialog visible={open} onClose={handleCloseModal}>
    <DialogTitle>{ }</DialogTitle>
    <DialogContent>
      <DialogContentText>

        {t('coming_soon')}
      </DialogContentText>
      <DialogContentText>

        {t('new_version_available')}
      </DialogContentText>

    </DialogContent>
    <DialogActions>
      <Button onClick={handleCloseModal}>{t('skip')}</Button>
      <Button color='var(--primary)' onClick={handleRefresh}>{t('refresh')}</Button>
    </DialogActions>
  </Dialog>

}


const Help= ({open,handleCloseHelp}:{open:boolean, handleCloseHelp:()=>void}) =>{

  return  <Dialog visible={open} onClose={handleCloseHelp}>
  <DialogTitle>{ }</DialogTitle>
  <DialogContent style={
    
    
    
    {height: "80vh"}
    
  }
  >
    <DialogContentText>
<h2>

Fomba fanokafana kaonty Malen
</h2>

NB: Mila manana connection aterneto na Mega ianao amin’ny voalohany vao afaka mahazo ny application Malen. Rehefa avy nalaina avy tao amin’ny aterneto iroe lesona dia afaka henoinao foana na tsy misy aterneto aza aorian’izay

<h4>

Dingana 1: Tsindrio ny hoe hisoratra anarana eny amin’ny sisiny ankavanana ambony.
</h4>
  <img src={appHome} style={{width: "90vw",border:" currentColor",
    borderStyle: 'dotted'}} alt="glogo" />


<h4>

Dingana 2: Safidio ny fomba hisoratanao anarana: amin’ny alalan’ny compte Google-nao na ny compte Facebook-nao
</h4>
<img src={appLogin} style={{width: "90vw",border:" currentColor",
    borderStyle: 'dotted'}} alt="glogo" />


Arahabaina ianao nahasokatra kaonty Malen!
<h2>

II- Fomba hisafidinana teny ampisaina
Amin’ny sisiny ankavanana eny ambony dia ahitanao ny hoe MG sy EN
</h2>
<img src={appMenu} style={{width: "90vw",border:" currentColor",
    borderStyle: 'dotted'}} alt="glogo" />

<h2>

III- Fomba fividianana lesona:
</h2>
<ul style={{padding: "24px"}}>
  <li>
Alefaso ny saran’ny lesona 1 000 Ar amin’ny Mvola<br/> (0346309636- Andriantsarasandratra)

  </li>
  <li>
Alefaso amin’ny hafatra miafina amin’ny pejy <a href={"https://www.facebook.com/profile.php?id=100093386623113"} target={"_blank"} rel="noreferrer">Facebook Malen</a> ny adiresy nanokafanao kaonty Malen sy ny reference Mvola. 

  </li>
</ul>
<h2>

IV- Fomba fananovana “telechargement” lesona
</h2>
<h3>

Dingana 1: Midira amin’ny kaontinao Malen
</h3>
<img src={appLessons} style={{width: "90vw",border:" currentColor",
    borderStyle: 'dotted'}} alt="glogo" />

<h3>
Dingana 2: Tsindrio ny tondro famantarana ny telechargement <span><img src={downloadBtn} style={{height: "13vw",border:" currentColor",
    borderStyle: 'dotted'}} alt="glogo" /></span>  eo ampovoany na koa ny tondro fampantarana “Play” <span><img src={playBtn} style={{height: "13vw",border:" currentColor",
    borderStyle: 'dotted'}} alt="glogo" /></span> 

</h3>

    </DialogContentText>
    <DialogContentText>
    </DialogContentText>

  </DialogContent>
  <DialogActions>
    <Button color='var(--primary)' onClick={handleCloseHelp}>Iala</Button>
  </DialogActions>
</Dialog>




 
}