import ReactJkMusicPlayer, { ReactJkMusicPlayerAudioInfo, ReactJkMusicPlayerAudioListProps } from 'react-jinke-music-player'
import 'react-jinke-music-player/assets/index.css'
interface Props {

    tracks: ReactJkMusicPlayerAudioListProps[]
    playerInstance: any
    // setInstance: (inst: any) => void
    playIndex?: number
    onAudioPlayTrackChange?: (
        currentPlayId: string,
        audioLists: Array<ReactJkMusicPlayerAudioListProps>,
        audioInfo: ReactJkMusicPlayerAudioInfo,
    ) => void
}


export default function AudioPlayer({ tracks, playerInstance, playIndex, onAudioPlayTrackChange }: Props) {

    console.log({ tracks });

    return (<>
        <ReactJkMusicPlayer autoPlay={false} autoPlayInitLoadPlayList={false} ref={playerInstance} audioLists={tracks} onAudioPlayTrackChange={onAudioPlayTrackChange} playIndex={playIndex} showDownload={false} showThemeSwitch={false} />

    </>

    );
}
