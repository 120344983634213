import { TDescendant } from "@udecode/plate";
import Dexie, { Table } from "dexie";
import { RemoteLessonItemDoc } from "./remoteDB";

export interface LocalLessonItemDoc extends RemoteLessonItemDoc {
  completed: boolean;
  availableOffline: boolean;
  recording: Blob | null;
  progress: number;
  notesUser: TDescendant[];
  ver: number;
  recId: string;
}

class LocalDB extends Dexie {
  lessons!: Table<LocalLessonItemDoc>;

  constructor() {
    super("MALENLocalDB");
    this.version(1).stores({
      lessons: `
        id,
        originDBId,
        recording,
        recId,
        title,
        notes,
        notesUser,
        progress,
        ver`,
    });
  }
}

export const localDB = new LocalDB();
